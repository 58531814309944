:root {
    --main-brand-color: #000fff;
    --bg-color: #282a36;
    --main-text-color: white;
    --light-color: white;
    --medium-color: #bebebe;
    --sber-color: #19bb4f;
}

html {
    font-size: 16px;
}

.theme--white {
    --bg-color: white;
    --main-text-color: black;
}

.bg-black {
    background-color: var(--bg-color);
}

body {
    background-color: var(--bg-color);
    color: var(--main-text-color);
}

* {
    box-sizing: border-box;
    font-family: 'PT Mono', monospace;
    /*color: var(--main-text-color);*/
}

*::selection {
    background-color: var(--main-brand-color);
}

.section {
    padding: 20px 0;
}

@media (min-width: 576px) {
    .section {
        padding: 50px 0;
    }
}

a {
    color: var(--main-text-color);
    text-decoration: underline;
}

a:active, a:focus, a:hover, a.active {
    text-decoration: none;
    background-color: var(--main-brand-color);
    outline: 2px solid var(--main-brand-color);
    color: var(--light-color);
    /*transition: all 0.2s ease-in-out;*/
}

p {
    line-height: 1.5;
}

.grow:focus, .grow:hover {
    position: relative;
    z-index: 1;
}

.andrew {
    display: inline-block;
    width: 100%;
    font-size: 38px;
    line-height: 34px;
    color: var(--main-text-color);
    padding-left: 5px;
    padding-bottom: 10px;
    border-bottom: 13px solid var(--medium-color);
    margin-bottom: 20px;
}

@media (min-width: 576px) {
    .andrew {
        width: auto;
    }
}

.name::after {
    content: '0';
    color: var(--light-color);
    background-color: var(--main-brand-color);
    font-weight: bold;
    font-size: 1.1em;
    vertical-align: super;
    margin-left: 3px;
    padding: 0 0.3em;
}

.header {
    margin-top: 1em;
    margin-bottom: .5em;
}

.header h1, h2, h3, h4, h5 {
    margin-top: 0.2em;
    margin-bottom: 0.2em;
}

b {
    background-color: var(--main-brand-color);
    color: var(--light-color);
    padding: 0 0.25em;
}

.measure {
    max-width: 50em;
}

.measure-narrow {
    max-width: 30em;
}

/*old*/
.text--old {
    opacity: 0.6;
    text-decoration: line-through;
    transition: all 0.2s ease-in-out;
}

.text--old:hover {
    text-decoration: initial;
    opacity: 1;
}

.company-name {
}

.company-name--sberbank {
    background: var(--sber-color);
    font-weight: 100;
}

/* city */
.city-name {
}

/*link*/

.link {
    text-decoration: underline;
}

.link--www {
    position: relative;
    overflow: hidden;
    margin-right: 27px;
}

.link--www:after {
    content: '🔗';
    bottom: -4px;
    right: -30px;
    position: absolute;
    padding-left: 0.3em;
}

/*separator*/

.separator {
    display: block;
    width: 100%;
    min-height: 1px;
    background-color: var(--main-text-color);
}

.separator--inline {
    display: inline-block;
    width: auto;
}

.separator--hidden {
    background-color: transparent;
}

.separator--one {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
}

.button-reset {
    border: none;
    margin: 0;
    padding: 0;
    width: auto;
    overflow: visible;

    background: transparent;

    /* inherit font & color from ancestor */
    color: inherit;
    font: inherit;

    /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
    line-height: normal;

    /* Corrects font smoothing for webkit */
    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;

    /* Corrects inability to style clickable `input` types in iOS */
    -webkit-appearance: none;
}

